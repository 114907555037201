/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/js/aa/travelInformation/flightStatus.js
*    TO   --> ./legacy-web/src/main/assets/js/travel-information/flight-status.js
**/

/* global launchAirportCode:true */

AAcom('browserdetect', 'utilities', 'ajax', 'aaAutoComplete', 'travelDates', 'airportLookup', 'nearestAirportLookup', function(AAUI) {

    function showCity() {
        $j("#flightStatusView .flightSection").hide();
        $j("#flightStatusView .citySection").show();
        var locale = AAUI.getProperty('user.locale').substring(0,2);
        if(locale=='ja') {
        	$j("#originAirportLookup").hide();
        	$j("#destinationAirportLookup").hide();
        }
    }

    function showFlightNumber() {
        $j("#flightStatusView .citySection").hide();
        $j("#flightStatusView .flightSection").show();
    }

    function changeUrl(page, url) {
        if (typeof (history.pushState) != "undefined") {
            var obj = { Page: page, Url: url };
            history.pushState(obj, obj.Page, obj.Url);
        }
    }

    function getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    function selectFlightNumber() {
    	showFlightNumber();
    	var fltNumberSearch = $j("#flightNumberSearch")[0];
    	if (fltNumberSearch) {
    	    fltNumberSearch.checked = true;
            fltNumberSearch.form.action = "/travelInformation/flights/status/lookup";
    	}
    }

    function selectCitySearch() {
    	showCity();
    	var citySearch = $j("#citySearch")[0];
    	if (citySearch) {
    	    citySearch.checked = true;
            citySearch.form.action = "/travelInformation/flights/status";
    	}
    }

    AAUI.onChange("#flightNumberSearch", function(event){
    	AAUI.populateSpecificFlightSearchDateDropdown($j("#travelDateDropdown"), true, $j("#clientLocalTime"));
    	selectFlightNumber();
    	$j(".message-error").toggle();
    });

    AAUI.onChange("#citySearch", function(event) {
    	AAUI.populateSpecificFlightSearchDateDropdown($j("#travelDateDropdown"), true, $j("#clientLocalTime"));
    	selectCitySearch();
    	$j(".message-error").toggle();
    });

    AAUI.initAutoComplete(".aaAutoComplete");

    $j(function() {
    	var includeYesterday = true;
    	if (getParameterByName('searchType') === "flightnumber" || $j("#flightNumberSearch").attr("checked") === "checked") {
    		selectFlightNumber();
    	} else {
    		selectCitySearch();
    		includeYesterday = false;
    	}

        AAUI.initAirportLookup(".aaAirportLookup");
        AAUI.initNearestAirportLookup(".current-location-btn");
    	AAUI.populateSpecificFlightSearchDateDropdown($j("#travelDateDropdown"), includeYesterday, $j("#clientLocalTime"));
    });
});
